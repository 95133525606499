.stat-display-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30vw;
    height: 100%;
    /* background-color: #00FF00; */
}

.fancy-progress-bar {
    width: 100%;
}

.waffle-x-control-container {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    margin-top: 10px;
}

.line-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 40px;
}

.line-button {
    flex: auto;
    border: var(--text-color) 2px solid;
    background-color: var(--text-color);
    /* padding: 5px 20px; */
    border-radius: 12px;
    margin-right: 2.5px;
    transition: color 0.5s ease, background-color 0.5s ease, border 0.5s ease, flex-grow 0.5s ease;
    cursor: pointer;
    text-align: center;
    align-items: center;
    align-content: center;
    color: var(--background-color);
    font-family: GeneralSans-Medium;
    font-size: 20px;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
  }
  
  .line-button:hover {
    background-color: var(--accent-color);
    border: var(--accent-color) 2px solid;
  }
  
  .line-button.joined {
    flex: 0.5;
    border: var(--accent-color) 2px solid;
    background-color: var(--accent-color);
  }
  
  .line-button .button-content {
    transition: opacity 0.3s ease;
    width: 100%;
    height: 100%;
    align-content: center;
  }
  
  .line-button .button-content.fade-out {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  
  .line-button.joined .check-icon {
    color: var(--background-color);
    transition: color 0.5s ease;
  }
  
  .line-button.joined:hover .check-icon {
    color: var(--text-color);
  }

  .line-share-button {
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;
    margin-left: 2.5px;
    border: var(--text-color) 2px solid;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    transition: flex 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
    fill: var(--text-color);
  }
  
  .line-share-button:hover {
    border-color: var(--accent-color);
    fill: var(--accent-color);
  }
  

  .line-share-button.joined {
    flex: 0.5;
    border-color: var(--accent-color);
    fill: var(--accent-color);
  }
  
  .line-share-button.joined:hover {
    border-color: var(--text-color);
    fill: var(--text-color);
  }

  .line-share-button .share-icon {
    width: 75%;
    height: 75%;
    object-fit: contain;
    transition: fill 0.5s ease;
    fill: inherit;
  }
  
.waffle-impact-label {
    margin-right: 0px;
}


.line-filter-container {
    display: inline-block;
    white-space: nowrap;
  }
  
.waffle-filter-label {
display: inline-block;
margin-bottom: 20px;
}

.waffle-impact-label{
    width: fit-content;
}

.filter-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.waffle-year-range{
    margin-bottom: 30px;
    width: 100%;
    height: 40px;
    display: flex;
    padding: 0px;
    background-color: var(--light-bounding-color);
    border-radius: 12px;
    border: 2px solid var(--light-bounding-color);
    /* font stuff */
    text-align: center;
    color: var(--text-color);
    font-family: GeneralSans-Medium;
    font-size: 20px;
    transition: border 0.5s ease, color 0.5s ease;
    box-sizing: border-box;
}

/* opacity isn't a good fix, need to change to somthing that doesn't hurt readability as much */
.waffle-year-range:focus{
    outline: none;
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
}

.filter-waffle-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 40px;
}

.todays-stat-box {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: auto;
    border-radius: 12px;
    background-color: var(--light-bounding-color);
    text-align: center;
    align-content: center;
}

.joined-friends-wrapper .joined-friends-display {
    color: var(--accent-color);
    font-family: GeneralSans-Medium;
    font-size: 11px;
    opacity: 1;
    transition: opacity 0.5s ease;
    margin-left: 6px;
    margin-top: 2px;
}

.joined-friends-wrapper .joined-friends-display.fade-out {
    opacity: 0;
}

@media (max-width:500px) {
    .stat-display-column {
        width: 100%;
        gap:10px;
    }
    .filter-column{
        gap:5px;
    }
    .waffle-year-range{
        font-size: 15px;
    }
    .line-button .button-content{
        font-size: 15px;
    }
    .line-button{}
}