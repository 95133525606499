.search-bar {
    display: flex;
    align-items: center;
    width: 100%;
}

.search-bar-input {
    flex-grow: 1;
    background-color: var(--custom-bg-color, var(--text-color));
    border-radius: 12px 0 0 12px;
    height: 40px;
    border: none;
    color: var(--custom-text-color, var(--background-color));
    padding: 0 10px;
}
.search-bar-input::placeholder {
    color: var(--custom-text-color, var(--background-color));
    opacity: 0.6;
    transition: all 0.3s ease;
}


.search-bar-input:focus::placeholder {
    opacity: 1;
}

.search-bar-input:focus {
    outline: none;
}

.search-bar-cap {
    background-color: var(--custom-bg-color, var(--text-color));
    width: 40px;
    height: 40px;
    border-radius: 0 12px 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}

.search-bar-cap:hover {
    cursor: pointer;
    background-color: var(--accent-color);
}

.search-icon {
    fill: var(--custom-text-color, var(--background-color));
}