.label-container {
    position: relative;
    width: auto;
  }
  
  .label-text {
    position: absolute;
    top: -25px;  /* Adjust this value as needed */
    left: 0;
    color: #35363b;
    font-size: 15px;
    font-family: 'generalSans-SemiBold', sans-serif;
  }
  
  .label-content {
    width: 100%;
    height: 100%;
  }

@media (max-width:500px) {
    .label-text {
        top: -17px;
        font-size:12px;

    }
    
}