.redirect{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: var(--background-color);
    overflow-y:scroll;
    height:fit-content;
    padding: 30px 0;
    box-sizing: border-box;
    position: relative;
    .redirect-content{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        width: min(calc(100% - 40px), 400px);
        padding:30px 0;
        box-sizing: border-box;
    }
    
    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        gap:5px;
    }
    
    .header p{
        font-size: 1.5em;
        font-family: GeneralSans-Medium;
        color: var(--text-color);
        margin:0;
    
    }
    
    .boycott-alert{
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: center;
    }
    
    .boycott-alert .highlight{
        color: var(--accent-color);
    }

    .bypass{
        position: absolute;
        bottom: -34px;
        z-index: 9;
        button{
            font-size:14px;
            padding: 10px 30px;
        }
    }
}    



