:root {
  --switch-outer-bradius: 13px;
  --gap: 3px;
  --switch-inner-bradius: calc(var(--switch-outer-bradius) - var(--gap));
  --horizontal-padding: 12px;
  --vertical-padding: 5px;
  --transition: all 0.5s ease;
  --switch-font-size: 16px;
  --mobile-font-size: 13px;
}

.switch {
  position: relative;
  display: inline-flex;
  gap: var(--gap);
  padding: var(--gap);
  border-radius: var(--switch-outer-bradius);
  background-color: var(--light-bounding-color);
  box-sizing: border-box;
  align-items: center;
}



.switch .option {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: var(--vertical-padding) var(--horizontal-padding);
  border-radius: var(--switch-inner-bradius);
  z-index: 1;
  transition: var(--transition);
  -webkit-tap-highlight-color: transparent;
    
}

.switch .option:focus{
    outline:none;
}

.highlighted {
  position: absolute;
  height: calc(100% - var(--gap) * 2);
  background-color: var(--accent-color);
  transition: var(--transition);
  z-index: 0;
  border-radius: var(--switch-inner-bradius);
}

.option p {
  margin: 0;
  font-size: var(--switch-font-size);
  font-family: "GeneralSans-Medium";
  color: var(--black);
  transition: var(--transition);
  white-space: nowrap;
  font-weight:500;
  /* margin: 3px 10px; */
  
}

.option p:hover {
  color: var(--accent-color);
}

.option.selected p {
  color: var(--light-bounding-color);
}

// @media (max-width:500px) {
//     .option p{
//         font-size: var(--mobile-font-size);
//     }
    
// }

// @media (max-width:500px) {
// //     .switch{
// //         flex-direction: column;
// //     }
// // }