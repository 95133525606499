:root {
  --background-color: #fff;
  --text-color: #35363b;
  --light-grey-highlight: #9a9b9d;
  --light-bounding-color: #ededed;
  --accent-color: #eb675f;
  --alt-accent-color: #007bff;
}

/* temp margin for testing, should be removed later */
.line-viewer-connecter {
  display: flex;
  align-items: center;
  margin-top:20px;
  gap:20px;
}

.line-selector-container{
    width:35vw;
    
}
.line-selector-ui {
  margin-top: 0px;
  margin-right: 0px;
  width:100%;
}

.urgent-lines-container {
  width: 35vw;
  display: flex;
  flex-direction: column;
  padding: 10px;
  -webkit-user-select: none;
  user-select: none;
}

.urgent-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.5s ease, fill 0.5s ease;
}

.check-box {
  width: 40px;
  height: 40px;
  background-color: var(--light-bounding-color);
  border-radius: 12px;
  margin-right: 10px;
  transition: opacity 0.5s ease, width 0.5s ease, margin-right 0.5s ease,
    background-color 0.5s ease; /* Smoothly hide checkbox */
}

.check-box:hover {
  background-color: var(--light-grey-highlight);
}

.check-box.hide {
  opacity: 0;
  width: 0;
  margin-right: 0;
}

.content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 10px;
  height: 40px;
  background-color: var(--light-bounding-color);
  color: var(--text-color);
  fill: var(--text-color);
  border-radius: 12px;
  transition: flex-grow 0.5s ease, fill 0.5s ease;
}

.content-box:hover {
  color: var(--accent-color);
  fill: var(--accent-color);
}

.urgent-line.selected .content-box {
  transition: flex-grow 0.5s ease, fill 0.5s ease;
  background-color: var(--text-color);
  color: var(--background-color);
  fill: var(--background-color);
  flex-grow: 1;
}

/* .urgent-line.selected .content-box:hover {
  color: var(--accent-color);
  fill: var(--accent-color);
} */

.line-name {
  color: inherit;
  font-family: GeneralSans-Medium;
  font-size: 1em;
  transition: color 0.5s ease;

}

.line-participants {
  display: flex;
  align-items: center;
  color: inherit;
  font-family: GeneralSans-Medium;
  font-size: 15px;
  transition: color 0.5s ease;
}

.participants-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: inherit;
  transition: fill 0.5s ease;
}

.join-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.join-selected-button {
  border: var(--accent-color) 2px solid;
  padding: 5px 25px;
  border-radius: 12px;
  transition: background-color 0.5s ease, border 0.5s ease;
  cursor: pointer;
  font-family: GeneralSans-Medium;
  font-size: 1em;
  color: var(--background-color);
  background-color: var(--accent-color);
}

.join-selected-button.no-selection {
  border: var(--light-bounding-color) 2px solid;
  color: var(--text-color);
  background-color: var(--light-bounding-color);
}

.join-selected-button:hover {
  color: var(--background-color);
  background-color: var(--text-color);
  border: var(--text-color) 2px solid;
}

.last-hovered {
  flex: auto;
  margin-top: 20px;
  font-family: GeneralSans-Medium;
  font-size: 1em;
  color: var(--text-color);
}

.label-grid {
  display: grid;
  grid-template-columns: 3vw auto 1fr auto 1fr;
  align-items: left;
  margin-bottom: 2px;

  
  font-family: GeneralSans-SemiBold;
  font-size: 15px;
}

.label-first {
  margin-left: 0.25vw;
  grid-column: 0;
  
  font-family: GeneralSans-SemiBold;
  font-size: 15px;
}

.label-second {
  text-align: start;
  grid-column: 2;
  
  font-family: GeneralSans-SemiBold;
  font-size: 15px;
}

.label-third {
  text-align: end;
  grid-column: 5;
  margin-right: 0.5vw;

  
  font-family: GeneralSans-SemiBold;
  font-size: 15px;
}

.last-hovered {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.last-hovered.visible {
  opacity: 1;
}

.red-text {
  color: var(--accent-color);
}

.red-icon {
  fill: var(--accent-color);
}

.fading-out {
  transition: color 0.5s ease, fill 0.5s ease;
}

@media (max-width: 500px) {
  .line-viewer-connecter{
    flex-direction: column-reverse;
    width:100%;
    align-items: center;
    justify-content: center;
    .line-selector-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:90vw;
        .line-selector-ui{
            width:100%;
            .urgent-lines-container{
                .urgent-line{
                    -webkit-tap-highlight-color: transparent;

                }
                .check-box{
                    width:35px;
                    height:35px;
                    &.hide{
                        width:0;
                        margin-right:0;
                        opacity:0;
                    }
                }
                .content-box{
                    height:35px;
                }
                .line-name{
                    max-width:60%;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 150px;
                }
                width:100%;
                box-sizing: border-box;
                .label-grid{
                    grid-template-columns: calc(40px + 10px) auto 1fr auto 1fr;
                }
            }
        }
    }
    .last-hovered {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }
}