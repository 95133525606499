:root {
  --background-color: #fff;
  --text-color: #35363b;
  --light-bounding-color: #ededed;
  --dark-bounding-color: #B0AFAF;
  --accent-color: #eb675f;
  /* these 3 accents need to be redone, the orange is good but the yellow and blue are too light */
  /* might not matter if we replace with an actual effect, mostly temp solution */
  --accent-yellow: #fff44f;
  --accent-orange: #ffa500;
  --accent-ice-blue: #a5f2f3;
  --faded-accent-color: #fcaba3;

  --accent-green: #61c453;
}

:root {
  --white: #ffffff;
  --black: #35363b;
  --red: #eb675f;
  --green: #61c453;
  --grey: #f2f2f2;
  --dark-grey: #cccdce;
  --font: "General Sans";
  --font-weight: 500;
}
/* might need to add a few more colors and/or rename */
