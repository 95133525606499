.search-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 55px;
  }
  
  .search-mobile-banner {
    top: 55px;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--background-color);
  }
  
  .search-mobile-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    width: 100%;
  }
  
  .search-mobile-filter {
    margin-top: 10px;
    width: auto;
    z-index: 9;
    background: var(--background-color);
  }
  
  .search-mobile-results {
    width: 96vw;
    margin-left: 2vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .search-mobile-new-line-btn {
    height: 40px;
    width: 40px;
    background-color: var(--accent-color);
    color: var(--background-color);
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 8px;
    text-align: center;
    align-content: center;
    font-size: 25px;
    font-family: "GeneralSans-Regular";
    transition: all 0.5s ease;
    z-index: 10;
    
    &:hover {
      background-color: var(--text-color);
      cursor: pointer;
    }
  }