.line {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  
    &-page-contents {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
  
      .line-cover {
        width: 40vw;
        height: 100%;
        border-radius: 16px;
        margin-right: 20px;
      }
  
      .title-cover-alignment {
        margin-top: 40px;
        margin-bottom: 40px;
      }
  
      .title-card {
        width: 40vw;
        margin-bottom: 10px;
  
        .line-title {
          font-family: generalSans-Bold;
          font-size: 50px;
        }
  
        .line-owner-tag {
        margin: 10px 0;
          font-family: GeneralSans-medium;
          font-size: 20px;
          align-items: center;
          display: flex;
          transition: color 0.5s ease;
          color: var(--text-color);
          cursor: pointer;
          margin-bottom: 10px;
  
          &:hover {
            color: var(--accent-color);
          }
        }
  
        .line-owner-pfp-container {
          width: 25px;
          height: 25px;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          flex-shrink: 0;
  
          .line-owner-pfp-home {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
  
      .cover-and-stat-alignment {
        display: flex;
        flex-direction: row;
        height: 350px;
        overflow: visible;
        width: 100%;
        margin-bottom: 60px;
      }
  
      .line-bio-wrapper {
        width: 40vw;
        margin-right: 20px;
      }
  
      .line-bio-and-included-sites {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
      }
      
      .related-lines-container {
        width: 35vw;
        margin-top: 15px;
      }
  
      .included-lines-wrapper {
        width: auto;
      }
    }
  }
  
@media (max-width:500px) {
    .line {
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
      
        &-page-contents {
          align-items: center;
          align-content: center;
          display: flex;
          flex-direction: column;
          width: 100vw;
          box-sizing: border-box;
          height: 100vh;
      
          .line-cover {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            margin-right: 20px;
          }
      
          .title-cover-alignment {
            padding:0 20px;
            box-sizing: border-box;
            margin-top: 20px;
            margin-bottom: 20px;
            max-width:100%;
          }
      
          .title-card {
            width: 40vw;
            margin-bottom: 10px;
      
            .line-title {
              font-family: generalSans-Bold;
              font-size: 35px;
            }
      
            .line-owner-tag {
              font-family: GeneralSans-medium;
              font-size: 15px;
              align-items: center;
              display: flex;
              transition: color 0.5s ease;
              color: var(--text-color);
              cursor: pointer;
              margin-bottom: 10px;
      
              &:hover {
                color: var(--accent-color);
              }
            }
      
            .line-owner-pfp-container {
              width: 20px;
              height: 20px;
              border-radius: 10px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ff0000;
              margin-right: 10px;
              flex-shrink: 0;
      
              .line-owner-pfp-home {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
      
          .cover-and-stat-alignment {
            display: flex;
            flex-direction: column;
            height: fit-content;
            overflow: visible;
            width: 100%;
            margin-bottom: 20px;
            gap:10px;
          }
      
          .line-bio-wrapper {
            width: 50%;
            margin-right: 20px;
          }
      
          .line-bio-and-included-sites {
            display: flex;
            flex-direction: row;
            width: 200%;
            height: fit-content;
            gap:10px;
            transition: transform 0.5s;
            &.included-sites{
                transform:translateX(calc(-50% - 10px));
            }
          }
          .related-lines-container {
            width: 100%;
            margin-left:0;
          }

          .bio-switch-container{
            margin-bottom:30px;
          }
      
          .included-lines-wrapper {
            width: 50%;
            
          }
        }
      }
      
}