:root {
    --logo-size: 100%;
    --line-thickness: 10%;
    --bg-color: #EB675F;
    --border-radius: 20%;
    --line-border-radius: 10px;
    --animation-duration: 0.8s;
}

.logo-container {
    position: relative;
    width: var(--logo-size);
    height: var(--logo-size);
    background-color: var(--bg-color);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.diagonal-line {
    position: absolute;
    width: 0;
    height: var(--line-thickness);
    border-radius: var(--line-border-radius);
    background-color: var(--background-color);
    top: 0;
    left: 0;
    transform: rotate(45deg) translateY(calc(-1 * var(--line-thickness) / 2)) translateY(-1px);
    transform-origin: top left;
    animation: draw-line var(--animation-duration) forwards;
}

@keyframes draw-line {
    from {
        width: 0; 
    }
    to {
        width: calc(var(--logo-size) * 1.414); /* 100 * sqrt(2) to cover the diagonal */
    }
}