.pinned-line-previewer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  cursor: pointer;
}

.pinned-line-previewer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: var(--light-bounding-color);
  border-radius: 12px;
  box-sizing: border-box;
  color: var(--text-color);
  fill: var(--accent-color);
  transition: background-color 0.5s ease;

  &:hover {
    background-color: var(--accent-color);

    .participants-icon {
      fill: var(--background-color);
    }

    .line-participant-count,
    .line-owner-tag {
      color: var(--background-color);
    }
  }
}

.participants-icon {
  width: 27px;
  height: 27px;
}

.pinned-line-alignment {
  padding: 15px;
}

.heading-line {
  display: flex;
  flex-direction: row;
  gap: 10px;
  bottom: 10px;
}

.preview-title {
  font-family: GeneralSans-SemiBold;
  font-size: 24px;
  color: var(--text-color);
}

.line-thumb-nail {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  margin-bottom: 5px;
  border-radius: 12px;
}

.preview-bio {
  margin-bottom: 10px;
  font-family: GeneralSans-Regular;
  font-size: 16px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
}

.line-participant-count {
  align-items: center;
  display: flex;
  color: var(--accent-color);
  font-family: GeneralSans-SemiBold;
  font-size: 16px;
  transition: all 0.5s ease;
}

.line-owner-tag-container {
  display: flex;
  justify-content: flex-start;
}

.line-owner-tag {
  font-family: GeneralSans-Medium;
  font-size: 16px;
  align-items: center;
  display: flex;
  transition: color 0.5s ease;
  color: var(--accent-color);
}

.line-owner-pfp {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 6px;
}

@media (max-width: 768px) {
  .preview-title {
    font-size: 20px;
  }

  .preview-bio {
    font-size: 14px;
  }

  .line-participant-count,
  .line-owner-tag {
    font-size: 14px;
  }

  .line-owner-pfp {
    width: 30px;
    height: 30px;
  }
}