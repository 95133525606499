.profile-preview {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 500px;
  }
  
  .profile-image-container {
    width: 150px;
    height: 150px;
    border-radius: 12px;
    overflow: hidden;
    justify-content: center;
    margin-right: 25px;
    flex-shrink: 0;
    position: relative;
    border-radius: 12px;
  }

//   this image sizing is not right, need to fix this either through cropping more accuratly or
// by limiting size on submission
  .profile-image {
    width: 110%;
    height: 110%;
    object-fit: fill;
   }
  
  .profile-image-placeholder {
    width: 100%;
    height: 100%;
    background-color: blue;
    border-radius: 12px; // Add border radius to the placeholder
  }
  
  .profile-info {
    flex-grow: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 150px;
  }
  
  .profile-name {
    margin: 0 0 6px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'GeneralSans-SemiBold';
  }
  
  .profile-description {
    margin: 0;
    word-wrap: break-word;
    font-family: 'GeneralSans-Medium';
    overflow: hidden;
    flex-grow: 1;
  }