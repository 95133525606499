.tag-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .tag-item {
    padding: 5px 10px;
    border-radius: 12px;
    font-family: "GeneralSans-Medium";
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: var(--light-bounding-color);
    color: var(--text-color);
  }
  
  .tag-item:hover {
    background-color: var(--accent-color);
    color: var(--background-color);
  }
  
  .tag-item.selected {
    background-color: var(--accent-color);
    color: var(--background-color);
  }
  
  .tag-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .tag-item .remove-icon {
    margin-left: 5px;
    font-weight: bold;
  }