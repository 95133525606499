.number-scroller {
    display: flex;
    font-size: 40px;
    justify-content: center;
  }
  
  .digit-scroller {
    overflow: hidden;
    height: 1em; 
    width: 0.6em;
  }
  
  .digit-scroller-inner {
    display: flex;
    flex-direction: column;
  }
  
  .digit {
    height: 1em;
    line-height: 1em;
    text-align: center;
  }
  