html,
body {
    overflow-x: hidden;
}

.App {
    background-color: #fff;
    justify-content: center;
}

h1 {
    font-size: 1.5em;
    font-family: GeneralSans-Medium;
    color: var(--text-color);
    margin: 0;
    line-height: 0.9;
}

h2 {
    font-size: 1.5em;
    font-family: GeneralSans-Medium;
    color: var(--text-color);
    margin: 0;
}

h3 {
    font-size: 1.25em;
    font-family: GeneralSans-Medium;
    color: var(--text-color);
    margin: 0;
}

h4 {
    font-size: 1em;
    font-family: GeneralSans-Medium;
    color: var(--text-color);
    margin: 0;
}

p {
    font-size: 16px;
    font-family: GeneralSans-Medium;
    color: var(--text-color);
    margin: 0;
}

b{
    color:var(--accent-color);
}


button {
    font-size: 16px;
    font-family: GeneralSans-Medium;
    color: var(--background-color);
    background-color: var(--accent-color);
    border: none;
    padding: 6px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-family: GeneralSans-Medium;
    transition: all 0.5s;
}

button:hover {
    background-color: var(--text-color);
}

.page{
    padding-top:65px;
    position: relative;
    overflow-y:auto;
    max-height:100%;
    height:100%;
    box-sizing: border-box;
}

@media (max-width:500px) {
    h1 {
        font-size: 1.25em;
    }
    h2 {
        font-size: 1.25em;
    }
    h3 {
        font-size: 1em;
    }
    h4 {
        font-size: 1em;
    }
    p {
        font-size: 14px;
    }
    button {
        font-size: 14px;
    }
    .page{
        padding-top:55px;
    }
}

.requests{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 10px;
    border-radius: 12px;
    transition: background-color 0.5s ease;
    position:fixed;
    bottom:0;
    right:0;
    gap:2px;
    width: 200px;
    .request{
        display:flex;
        width:100%;
        gap:2px;
        background-color: var(--background-color);
        padding:2px 10px;
        border-radius: 10px;
        box-sizing: border-box;
        transform: translateX(-100px);
        transition: all 0.1s ease;
        opacity: 0;
        animation: fadein 0.1s forwards;
        &.from-cache{
            background-color: var(--light-bounding-color);
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
    
}