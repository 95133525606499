.line-preview{
    max-width: 100%;
    position: relative;
    width: 100%;
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.15);
    border-radius: 15px;
    overflow: hidden;
    img{
        width:100%;
    }
    
    .preview-content{
        position: absolute;
        top:0px;
        left:0px;
        padding: 60px 30px 30px 30px;
        width:calc(100% - 60px);
        display: flex;
        flex-direction: column;
        overflow:auto;
        gap:10px;
        h1{
            font-size: 20px;
            font-weight: 900;
        }
        p{
            font-size: 13px;
            font-weight: 500;
            text-wrap: wrap;
            max-width: 100%;
            color:inherit;
        }

        .line-preview-image {
            width: 225px;
            height: 125px;
            border-radius: 12px;

            background-color: blue; 
        }
    }
}

.line-preview.animation{

}

@media screen and (max-width: 500px){
    .line-preview{
        height:220px;
    }
    
}