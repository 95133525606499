@import url('./general-sans.css');
@import url('./colors.css');

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: Arial, sans-serif;
}


