/* FancyProgressBar.scss */
.fancy-progress-bar {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    font-family: generalSans-SemiBold;
  }
  
  /* Current Box Styles */
  .current-box {
    background-color: #eb675f;
    color: #ffffff;
    padding: 0 30px;
    border-radius: 12px;
  }
  
  .current-box .value {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
  }
  
  .current-box .subheading {
    font-size: 12px;
    font-family: generalSans-Regular;
  }
  
  /* Target Box Styles */
  .target-box {
    background-color: #ededed;
    color: #eb675f;
    padding: 0 30px;
    border-radius: 12px;
  }
  
  .target-box .value {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
  }
  
  .target-box .subheading {
    font-size: 12px;
    font-family: generalSans-Regular;
  }
  
  /* Progress Box Styles */
  .progress-box {
    background-color: #ededed;
    transition: width 0.3s ease, background-color 0.3s ease;
    border-radius: 12px;
  }
  
  .progress-box.filled {
    background-color: #eb675f;
  }
  
  /* Remaining Box Styles */
  .remaining-box {
    background-color: #ededed;
  }
  
  /* Common Box Styles */
  .box {
    height: 100%;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  
  .box:last-child {
    margin-right: 0;
  }