.Landing {
  justify-content: center;

  .title-underline {
    text-decoration: underline;
  }

  .landing-bounding-box-one {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* this -80 value should be calculated */
    padding-bottom: 60px;
    gap: 80px;

    .landing-text-and-button-wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      align-items: left;
      gap: 20px;
      width: 35vw;
      height: 100%;
    }

    .landing-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: 20px;
      width: 100%;
      // height: 100%;
    }

    .landing-title {
      font-size: 50px;
      font-family: generalSans-SemiBold;
    }

    .landing-description {
      font-size: 20px;
      font-family: generalSans-Medium;
    }

    .impact-grid-bounding-box {
      display: flex;
      justify-content: flex-start;
      width: 40vw;
    }
  }

  .landing-initial-frame {
    width: 100%;
    height: calc(100vh - 94px);
    max-height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .landing-bounding-box-two {
    display: flex;
    width: 80vw;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    padding: 50px;

    .stat-container{
        display:flex;
        width:50%;
        justify-content: space-between;
    }

    .landing-text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: 10px;
      width: 100%;
      height: 100%;
    }

    .landing-title {
      font-size: 45px;
      white-space: nowrap;
      font-family: generalSans-SemiBold;
      color: var(--background-color);
    }

    .landing-description {
      font-size: 20px;
      font-family: generalSans-Regular;
      color: var(--background-color);
    }

    .join-button {
      width: fit-content;
      text-align: center;
      justify-content: center;
      margin-left: auto;
    }

    .landing-text-and-button-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      height: 100%;
    }

    .temp-stats-home {
      height: auto;
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .landing-stat-wrapper {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .landing-stat-main {
    color: var(--accent-color);
    font-size: 40px;
    font-family: generalSans-SemiBold;
  }

  .landing-stat-sub-header {
    color: var(--background-color);
    font-size: 16px;
    font-family: generalSans-SemiBold;
  }

  .landing-initial-view-x-down-arrow-bounding {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .landing-arrow-down {
      font-size: 30px;
      color: var(--text-color);
      transition: all 0.5s ease;
      position: absolute;
      bottom: 10px;
      margin-bottom: 3%;

      &:hover {
        cursor: pointer;
        margin-bottom: 2%;
        padding-top: 1%;
        color: var(--accent-color);
      }
    }
  }
}

@media (max-width:500px) {
    .Landing{
        // ERROR: this calc needs to be different for mobile view, it's what is causing
        //  the next section to peak out on mobile rn
        .landing-initial-frame{
            height:calc(100%);
            max-height:calc(100% - 80px);
        }
        .landing-initial-view-x-down-arrow-bounding{
            padding-bottom: 45px;
            padding-top:30px;
            box-sizing: border-box  ;
            .landing-arrow-down{
                font-size: 25px;
                margin-bottom:1%;
                bottom:3px;
            }

        }
        .landing-bounding-box-one{
            flex-direction: column;
            gap: 30px;
            padding-bottom: 20px;
            .landing-text-and-button-wrapper{
                width: 80vw;
            }
            .landing-title{
                font-size: 35px;
            }
            .impact-grid-bounding-box{
                width: 80vw;
            }
        }
        .landing-bounding-box-two{
            width: 90vw;
            padding: 20px;
            .stat-container{
                display:flex;
                flex-direction: column;
                gap:20px;
            }
            .landing-title{
                font-size: 30px;
                text-wrap: wrap;
                text-align: center;
            }
            .landing-description{
                font-size: 16px;
            }
            .temp-stats-home{
                width: 100%;
                gap: 20px;
            }
        }
    }    
}