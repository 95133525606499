.line-entry {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
  
    &.dragging {
      opacity: 0.4;
      transform: scale(0.98);
    }
  
    .drag-handle-wrapper {
      display: flex;
      align-items: center; 
      justify-content: center;
      font-size: 20px;
      color: var(--dark-bounding-color);
      cursor: grab;
      transition: all 0.3s ease;
      flex-shrink: 0;
      height: 100%;
      opacity: 1;
  
      &:hover {
        color: var(--text-color);
      }
  
      &:active {
        cursor: grabbing;
      }
    }
  
  
    .url-entry-input-wrappers {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 100%;
      transition: width 0.3s ease;
      flex: 1;
  
      input {
        box-sizing: border-box;
        transition: width 0.3s ease;
        width: 33.33%;
        height: 100%;
        background-color: var(--light-bounding-color);
        border-radius: 12px;
        border: none;
        color: var(--text-color);
        font-family: "GeneralSans-Medium";
        padding: 0 10px;
  
        &.error {
          transition: all 0.3s ease;
          background-color: var(--accent-color);
        
          &::placeholder {
            color: var(--background-color);
          }
        }
      }
  
      &:has(input:focus) {
        .url-input,
        .description-input,
        .alternative-input {
          width: 10%;
        }
  
        .url-input:focus,
        .description-input:focus,
        .alternative-input:focus {
          width: 80%;
          outline: none;
        }
      }
    }
  
    .alternative-items-list-wrapper {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2px;
      overflow: hidden;
      transition: width 0.3s ease;
      margin-left: 5px;
  
      .alt-item {
        flex: 1;
        min-width: 2px;
        height: 100%;
        background-color: var(--light-bounding-color);
        color: var(--text-color);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6px;
        transition: all 0.3s ease;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        animation: altItemAppear 0.3s ease forwards;
        cursor: pointer;
        position: relative;
  
        .item-text {
          transition: opacity 0.2s ease;
        }
  
        .delete-x {
          position: absolute;
          opacity: 0;
          color: white;
          font-size: 24px;
          transition: opacity 0.2s ease;
        }
  
        &:hover {
          background-color: var(--accent-color);
  
          .item-text {
            opacity: 0;
          }
  
          .delete-x {
            opacity: 1;
          }
        }
  
        &.removing {
          animation: altItemRemove 0.3s ease forwards;
        }
      }
    }
  }
  
  @keyframes altItemAppear {
    from {
      flex: 0;
      opacity: 0;
    }
    to {
      flex: 1;
      opacity: 1;
    }
  }
  
  @keyframes altItemRemove {
    from {
      flex: 1;
      opacity: 1;
    }
    to {
      flex: 0;
      opacity: 0;
    }
  }