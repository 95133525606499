/* Alignment: */
.nav-bar {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 45px;
  background-color: transparent;
  -webkit-user-select:none;
  user-select: none;
  margin: 10px 0;  
  position: fixed;
  z-index: 100;
  top:0;
  left:0;
  padding: 0 20px;
  box-sizing: border-box;
}

.nav-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 42px;
}
.nav-left,
.nav-right {
  flex: 1;
}

.nav-left {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
}

.nav-right {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  gap:10px;
  .pfp{
    img{
        width:20px;
        height:20px;
    }
  }
}

/* Contents: */

/* relic code from when logo was png, still needed for space but could be reworked
to simply use  */
.break {
  margin-right: 15px;
}

/* signup/login button */
/* i feel these buttons are not consitent enough, also currently hardcoding sizes and I think that works but possibly should swap to text that scales */
.login-button {
  /* Box Style Settings */
  border: var(--light-bounding-color) 2px solid;
  padding: 4px 20px 4px 20px;
  border-radius: 12px;
  margin-right: 15px;
  transition: color 0.5s ease, background-color 0.5s ease, border 0.5s ease;
  cursor: pointer;
  /* Text Settings */
  font-family: GeneralSans-Medium;
  font-size: 16px;
  color: var(--text-color);
  background-color: var(--light-bounding-color);
}

.login-button:hover {
  color: var(--background-color);
  background-color: var(--text-color);
  border: var(--text-color) 2px solid;
}

.join-button {
  /* Box Style Settings */
  border: var(--accent-color) 2px solid;
  padding: 4px 30px 4px 30px;
  border-radius: 12px;
  transition: color 0.5s ease, background-color 0.5s ease, border 0.5s ease;
  cursor: pointer;
  /* Text Settings */
  font-family: GeneralSans-Medium;
  font-size: 16px;
  color: var(--background-color);
  background-color: var(--accent-color);
  font-weight:500;
}

.join-button:hover {
  color: var(--background-color);
  background-color: var(--text-color);
  border: var(--text-color) 2px solid;
}

@media(max-width:500px){
    .nav-bar{
        margin:5px 0;
        padding: 0 15px;
        .login-button,
        .join-button{
            padding: 4px 10px 4px 10px;
            font-size:15px;
        }
    }
}