.slider-container {
    position: relative;
    overflow: show;
    transition: height 0.7s ease-in-out;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;

    .slider-content {
        transition: opacity 1s ease-in-out;
        width:100%;
        max-width: 450px;
    }
    .button-container{
        position: absolute;
        bottom: -50px;
        display:flex;
        width:100%;
        justify-content: center;
        gap:10px;
        width:300px;

        button {
            margin-top: 10px;
        }    
        .back{
            flex-grow:1;
            background-color: var(--light-bounding-color);
            color: var(--text-color);
        }
        .next{
            flex-grow:2;
            background-color: var(--light-bounding-color);
            color: var(--text-color);
            transition: all 0.5s ease;
            // pointer-events: none;
            &.active{
                background-color: var(--accent-color);
                color: var(--background-color);   
            }
        }
    }

}



