.text-editor{

    .quill{
        position: relative;
    }

    .ql-toolbar{
        background-color: var(--light-bounding-color);
        border-radius: 10px;
        border:none;
        position: absolute;
        bottom:5px;
        left:5px;
        width:calc(100% - 10px);
        z-index: 999;
    }

    .ql-container{
        background-color: var(--light-bounding-color);
        border-radius: 10px;
        border:none;
        color: var(--text-color);
        height:250px;
        overflow-y: auto;
        padding-bottom:50px;
    }
}

@media (max-width:500px) {
    .text-editor{
        .ql-container{
            height:150px;
        }   
        .ql-toolbar{
            bottom:0;
            left:0;
            width:100%;
        }
    }
    
}