.line-analytics {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
    font-family: 'GeneralSans', sans-serif;

    .analytics-content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .analytics-spacer {
        display: flex;
        justify-content: space-between;
        width: 80%;
        height: 80%;
        gap: 20px;

        .primary-col {
            display: flex;
            flex-direction: column;
            width: 68%;
            border-radius: 16px;

            .graph-label {
                .graph-place-holder {
                    width: 100%;
                    height: 50vh;
                    border-radius: 12px;
                    background-color: var(--light-bounding-color);
                }
            }
        }

        .secondary-col {
            display: flex;
            flex-direction: column;
            width: 30%;
            border-radius: 16px;
            gap: 30px;

            .stats-label, .milestones-label, .key-sources-label {
                background-color: transparent;
            }

            .secondary-row {
                display: flex;
                justify-content: space-between;
                border-radius: 8px;
                color: var(--text-color);

                &.full-width {
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    background-color: var(--light-bounding-color);

                    .primary-value {
                        font-size: 3rem;
                        color: var(--text-color);
                        font-family: 'GeneralSans-Medium', sans-serif;
                    }

                    .secondary-title {
                        font-size: 1rem;
                        color: var(--secondary-text-color);
                        font-family: 'GeneralSans-Medium', sans-serif;
                        margin-bottom: 10px;

                        .arrow {
                            margin-left: 5px;
                            &.up {
                                color: var(--accent-green);
                            }
                            &.down {
                                color: var(--accent-color);
                            }
                        }
                    }
                }

                &.half-width {
                    gap: 10px;

                    .stat-box {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        background-color: var(--light-bounding-color);
                        border-radius: 8px;

                        .stat-value {
                            font-size: 2rem;
                            font-weight: bold;
                            font-family: 'GeneralSans-Medium', sans-serif;
                        }

                        .stat-label {
                            font-size: 0.875rem;
                            font-family: 'GeneralSans-Medium', sans-serif;
                        }
                    }
                }

                &.milestones-row {
                    display: flex;
                    gap: 10px;
                    background-color: transparent; // Remove background color

                    .milestone-box {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        background-color: var(--light-bounding-color);
                        border-radius: 8px;
                        cursor: pointer;

                        .milestone-value {
                            font-size: 1.5rem;
                            font-family: 'GeneralSans-Medium', sans-serif;
                        }

                        input {
                            font-size: 1.5rem;
                            font-family: 'GeneralSans-Medium', sans-serif;
                            text-align: center;
                            border: none;
                            background: transparent;
                            color: var(--text-color);
                            width: 100%;
                        }
                    }
                }
            }

            .key-sources-ranking {
                display: flex;
                flex-direction: column;
                gap: 5px;
                background-color: transparent; // Remove background color
                border-radius: 8px;

                .source-rank {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-family: 'GeneralSans-Medium', sans-serif;

                    .rank-number {
                        color: var(--secondary-text-color);
                    }

                    .source-name {
                        color: var(--text-color);
                    }
                }
            }
        }
    }
}

.confirmation-message {
    margin-top: 10px;
    border-radius: 8px;
    text-align: center;
    font-family: 'GeneralSans-Medium', sans-serif;
    background-color: var(--accent-green);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;
// for reasons i can not explain, i need the padding on just the error case for them to look the same...
// i can find no reason for this but will have to just ignore it for now.
    &.error {
        color: var(--background-color);
        background-color: var(--accent-color);
        padding: 10px;
        
    }

    &.success {
        color: var(--background-color);
        background-color: var(--accent-green);
    }
}

@media (max-width: 500px) {
    .line-analytics {
        .analytics-content-wrapper {
            width: 90%;
        }

        .analytics-spacer {
            flex-direction: column;
            width: 100%;
            height: auto;

            .primary-col, .secondary-col {
                width: 100%;
            }
        }
    }
}