.pickett-list {
    width: 100%;
    
    .pickett-list-scroll {
        height: 40vh;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        
        // Minimal scrollbar styling
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        
        &::-webkit-scrollbar {
            width: 6px;
        }
        
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
        }
    }
}