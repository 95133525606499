.text-input-x-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: var(--light-bounding-color);
    border-radius: 12px;
    padding: 0 5px 0 15px;
    box-sizing: border-box;
}


.text-input-x-button-wrapper .text-input {
    height: 100%;
    min-height: 36px;
    flex-grow: 1;
    background-color: transparent;
    border: none;
    color: var(--text-color);
    opacity: 0.5;
    transition: all 0.3s ease;
    font-family: "GeneralSans-Medium";
    font-size: 15px;
    min-width: 0;
}

.text-input-x-button-wrapper .text-input.has-input {
    opacity: 1;
}

.text-input-x-button-wrapper .text-input:focus {
    color: var(--text-color);
    outline: none;
    opacity: 1;
}

.text-input-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--text-color);
    border-radius: 10px;
    height: 32px;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.5s ease;
    border: none;
}

.text-input-button .text {
    color: var(--background-color);
    font-family: "GeneralSans-Medium";
    font-size: 15px;
    margin-right: 5px;
    transition: all 0.5s ease;
}

.text-input-button.has-input {
    background-color: var(--accent-color);
}

.text-input-button .arrow-icon-wrapper {
    color: var(--background-color);
    font-size: 20px;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
}

.text-input-button:hover {
    background-color: var(--accent-color);
}

.text-input-button:hover .arrow-icon-wrapper {
    translate: 5px;
}

.text-input-button.has-input:hover .text {
    /* color: var(--text-color); */
}

.text-input-button.has-input:hover .arrow-icon-wrapper {
    /* color: var(--text-color); */
}
