.pickett{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.pickett p{
    font-size:14px;
    font-family: GeneralSans-Medium;
    color: var(--text-color);
    margin:0;
}