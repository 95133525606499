.mobile-switch{
    overflow:show;
    position: relative;
    z-index: 10;
    .placeholder{
        margin:3px;
    }
    .switch{
        position: absolute;
        top:0;
        gap:0;
        flex-direction: column;
        left:0;
        .highlighted{
            transition: all 0.2s;
            width:calc(100% - var(--gap) * 2);
        }
        .highlighted{
            &.visible{
                opacity: 1;
            }
        }
    }
    .option {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: var(--vertical-padding) var(--horizontal-padding);
        border-radius: var(--switch-inner-bradius);
        z-index: 1;
        transition: var(--transition);
        -webkit-tap-highlight-color: transparent;
          
        transition: all 0.5s ease;
        p{
            transition: all 0.5s ease;
        }
        &.invisible{
            height:0;
            padding:0;
            overflow:hidden;
            p{
                height:0;
            }
        }
      }
}