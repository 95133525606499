
:root {
    --logo-size: 100%;
    --line-thickness: 10%;
    --line-color: var(--light-bounding-color);
    --bg-color: #EB675F;
    --border-radius: 20%;
    --line-border-radius: 10px;
    --animation-duration: 0.8s;
}
.custom-palette{
    display: flex;
    gap:5px;
    width:100%;
    .title{
        display:flex;
        gap:5px;
        width:40%;
    }
    .child{
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--light-bounding-color);
        padding: 7px 13px;
        font-family: 'GeneralSans-Medium';
        font-size: 14px;
        color: var(--text-color);
        box-sizing: border-box;

    }
    .name{
        width:60%;
    }
    .hex{
        width:40%;
    }
    .colors{
        display:flex;
        gap: 5px;
        padding:3px;
        .color{
            width:30px;
            height:30px;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            .diagonal-line {
                position: absolute;
                width: 0;
                height: var(--line-thickness);
                border-radius: var(--line-border-radius);
                background-color: var(--line-color);
                top: 0;
                left: 0;
                transform: rotate(45deg) translateY(calc(-1 * var(--line-thickness) / 2)) translateY(-1px);
                transform-origin: top left;
                animation: draw-line var(--animation-duration) forwards;
            }
            
            @keyframes draw-line {
                from {
                    width: 0; 
                }
                to {
                    width: calc(var(--logo-size) * 1.414); /* 100 * sqrt(2) to cover the diagonal */
                }
            }
        }
        
    }
}

@media screen and (max-width:500px) {
    .custom-palette{
        flex-direction: column;
        .title{
            width:100%;
        }
        .child{
            width:100%;
        }
        .colors{
            flex-wrap: wrap;
            .color{
                width: 30px;
                height: 30px;
            }
        }
    }
    
}