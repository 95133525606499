.new-user-setup{
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100%;
    height: 100%;
    min-height:100vh;
    

    .content{
        max-width: 450px;
        display: flex;
        flex-direction: column;
        gap:10px;
    }
    
    .join-header{
        position: relative;
        padding:20px 0;
        box-sizing: border-box;
        width:100%;
        max-width:600px;
        display:flex;
        align-items:flex-end;
        /* transform:translateX(-25px); */
        justify-content: center;
        gap:10px;
        h1{
            font-size:40px;
            margin:0;
            margin-bottom:3px;
        }

    }

}

@media screen and (max-width: 500px){
    .new-user-setup{
        .content{
            max-width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
        }
    }
    
}