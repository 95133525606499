.banner-container {
  width: 100%;
  overflow: hidden;
  background-color: var(--text-color);
  white-space: nowrap;
  position: relative;
  user-select: none;
}

.banner-content {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 40s linear infinite;
}

.banner-item {
  display: inline-block;
  padding: 0.3rem 1rem;
  font-size: 0.8rem;
  color: var(--background-color);
  font-family: "GeneralSans-Medium";
  transition: all 0.3s;
}

.banner-content.not-ready .banner-item {
  color: var(--text-color);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
    /* Adjust based on content length */
  }
}
