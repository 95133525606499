.animated-check-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.check {
  position: relative;
  width: 1.3em;
  height: 1.3em;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: var(--default-color);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, top 0.3s ease-in-out, left 0.3s ease-in-out, background-color 0.3s ease;
  }

  &::before {
    height: 0.15em; 
    width: 0.75em;
    border-radius: 3px;
    top: 0.85em;
    left: -0.05em;
    transform: rotate(45deg);
  }

  &::after { 
    height: 0.15em;
    width: 1.3em;
    border-radius: 3px;
    top: 0.6em;
    left: 0.25em;
    transform: rotate(-55deg);
  }
}

.animated-check-container.hovered .check {
  &::before,
  &::after {
    background-color: var(--hover-color);
  }

  &::before {
    transform: rotate(-45deg);
    width: 1.2em;
    top: 0.6em;
    left: 0.25em; 
  }

  &::after {
    transform: rotate(45deg);
  }
}