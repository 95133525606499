.account-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.account-settings-bounding {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 80%;
    gap: 10px;
}

.account-settings-title {
    font-size: 50px;
    font-family: "GeneralSans-Medium";
    color: var(--text-color);
}

.account-settings-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 10px;

    .account-settings-subtitle {
        font-size: 30px;
        font-family: "GeneralSans-Medium";
        color: var(--text-color);
    }

    .account-settings-section-divider {
        width: 100%;
        height: 1px;
        background-color: var(--dark-bounding-color);
    }

}

.account-settings-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-settings-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 65px;
    width: 98%;

    .current-setting-status-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
        font-size: 15px;

        .setting-name {
            font-family: "GeneralSans-SemiBold";
            color: var(--text-color);
        }

        .setting-value {
            font-family: "GeneralSans-Medium";
            color: var(--dark-bounding-color);
            
        }

        .change-setting-input {
            width: 100%;
            box-sizing: border-box;
            padding: 5px 10px;
            border-radius: 10px;
            align-items: center;
            border: none;
            font-family: "GeneralSans-Regular";
            font-size: 15px;
            background-color: var(--light-bounding-color);
            color: var(--text-color);
            opacity: 0.7;
            transition: all 0.3s ease;

            &:focus {
                outline: none;
                opacity: 1;
            }
        }

        

    }

    .change-setting-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .update-setting-button {
            box-sizing: border-box;
            background-color: var(--light-bounding-color);
            color: var(--text-color);
            padding: 10px 15px;
            border-radius: 10px;
            font-family: "GeneralSans-Medium";
            font-size: 15px;
            transition: all 0.4s ease;
            cursor: pointer;

            &:hover {
                color: var(--accent-color);
            }

            
        }

        .update-setting-button.save {
            background-color: var(--accent-color);
            color: var(--background-color);
        }

        .update-setting-button.save:hover {
            background-color: var(--text-color);
        }
    }
}

