.error-redirect{
    display:flex;
    justify-content:center;
    align-items:center;
    .error-content{
        display:flex;
        flex-direction:column;
        gap:20px;
        align-items: center;
        margin-bottom:50px;
        max-width:min(calc(100% - 40px), 400px);
        h1{
            font-size:35px;
        }
        p{
            text-align: center;
        }
        
    }
}