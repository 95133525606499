.login,
.register{
    display:flex;
    height:100vh;
    max-height: 100%;
    flex-direction:row;
    .left{
        flex-grow:1;
        height:100%;
        background-color: var(--light-bounding-color);
    }
    .right{
        height:100%;
        display:flex;
        flex-direction:column;
        padding: 60px;
        box-sizing: border-box;

    }
    

}

@media (max-width:500px) {
    .login, .register{
        .left{
            display:none;
        }
        .right{
            width:100%;
            padding:40px;
        }
    }
}