.temp-vert-aligner {
    display: flex;
    flex-direction: column;
    align-items: left;
    align-content: center;
    justify-content: space-between;
    margin-top: 30px;
}

.temp-horz-aligner-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    gap: 20px;
}


.temp-horz-aligner-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
    width: 80vw;
    gap: 20px;
}
