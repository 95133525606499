@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 300px;
    align-items: center;
    font-family: Inter, sans-serif;
    height: 100%;
}

.form input {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 25px 20px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    background-color: #f5f5f5;
    color: #414141;
    box-sizing: border-box;
    outline: none;
    border: none;
    position: relative;
    font-weight: 500;
}

.form input::placeholder {
    color: #b1b1b1;
}

.email p,
.password p,
.username p {
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #414141;
    margin: 0 0 5px 7px;
}

.email,
.password,
.username {
    position: relative;
    width: 100%;
}

.already {
    font-size: 13px;
    font-family: Inter, sans-serif;
    color: #414141;
    width: 100%;
    text-align: left;
    margin: 0 0 0 7px; 
}

.button {
    align-items: center;
    background-color: #f5f5f5;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    color: #b1b1b1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    height: 50px;
     
    justify-content: center;
    margin-top: 10px;
    outline: none;
    pointer-events: none;
    position: relative;
    transition: all 0.3s ease;
    width: 100%;

    &.active {
        pointer-events: all;
        background-color: var(--accent-color);
        color: var(--background-color);
    }

    &.invisible{
        opacity: 0;
        pointer-events: none;
    }
}

.google {
    background-color: #1e1e1e;
    color: #ffffff;
    margin-top: 0;
    pointer-events: all;
}

.logo-login {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.divider {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    align-items: center;
}

.email-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;

    .show {
        height: 50px;
        margin-top: 0;
        min-height: 50px;
        position: absolute;
        
        top: 0;
        left: 0;
        .already{
            font-size: 13px;    
            font-weight: normal;
            position: absolute;
            bottom:-30px;
        }
    }

    .form {
        opacity: 0;
        transition: all 0.3s ease;
        pointer-events: none;

        &.visible {
            pointer-events: all;
            animation: opacity 0.3s forwards;
            animation-delay: 0.3s;
        }

        @keyframes opacity {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}

.divider hr {
    width: 100%;
    height: 2.1px;
    background-color: #dddcdc;
    margin: 0;
    border: none;
    border-radius: 3px;
}

.divider p {
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #414141;
    margin: 0 10px;
}

.google-button {
    width: 100% !important;
}

.error {
    font-family: Inter, sans-serif;
    position: relative;
    font-size: 14px;
    padding: 10px 15px;
    font-weight: 500;
    color: #fd5858;
    background-color: rgba(250, 117, 109, 0.16);
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    gap: 10px;
    overflow: hidden;
}

.error img {
    width: 20px;
    height: 20px;
}

.error::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fd5858;
    width: 7px;
    opacity: 0.8;
    border-radius: 15px 0 0 15px;
}

@media (max-width:500px) {
    .form {
        width: 100%;
    }
    
}