.file-upload {
    background-color: var(--light-bounding-color);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.5s ease;
    cursor: pointer;
  
    &:hover {
      fill: var(--accent-color);
      
      h3, p, .upload-icon {
        color: var(--accent-color);
      }
    }
  
    .icon-container {
      margin-bottom: 10px;
    }
  
    .upload-icon {
      font-size: 40px;
      color: var(--dark-bounding-color);
      transition: all 0.5s ease;
    }
  
    .check-icon {
      color: var(--background-color);
    }
  
    .text-container {
      text-align: center;
    }
  
    h3 {
      font-family: "GeneralSans-Medium";
      font-size: 15px;
      color: var(--dark-bounding-color);
      margin: 0;
      margin-bottom: 10px;
      transition: all 0.5s ease;
    }
  
    p {
      font-family: "GeneralSans-Regular";
      font-size: 12px;
      color: var(--dark-bounding-color);
      margin: 0;
      transition: all 0.5s ease;
    }
  
    &.complete {
      background-color: var(--text-color);
  
      h3, p {
        color: var(--background-color);
      }
  
      &:hover {
        background-color: var(--accent-color);
      }
    }
  }