.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 65px;
    
    .main-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
        gap: 30px;
        width: 70vw;
        
        h2 {
            font-family: "GeneralSans-Medium";
            font-size: 50px;
        }

        p {
            font-family: "GeneralSans-Medium";
            font-size: 30px;
        }

    }
}
