:root {
  --background-color: #fff;
  --text-color: #35363b;
  --light-grey-highlight: #9a9b9d;
  --light-bounding-color: #ededed;
  --accent-color: #eb675f;
  --alt-accent-color: #007bff;
}

.line-viewer-connector {
  display: flex;
}

.line-viewer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  user-select: none;
}

.line-viewer-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.5s ease, fill 0.5s ease;
}

.line-viewer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 10px;
  height: 4vh;
  background-color: var(--light-bounding-color);
  color: var(--text-color);
  fill: var(--text-color);
  border-radius: 12px;
  transition: flex-grow 0.5s ease, fill 0.5s ease;

  &:hover {
    color: var(--accent-color);
    fill: var(--accent-color);
  }
}

.line-viewer-item-name {
  color: inherit;
  font-family: GeneralSans-Medium;
  font-size: 1em;
  transition: color 0.5s ease;
  overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}

.line-viewer-item-participants {
  display: flex;
  align-items: center;
  color: inherit;
  font-family: GeneralSans-Medium;
  font-size: 15px;
  transition: color 0.5s ease;
}

.line-viewer-participants-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: inherit;
  transition: fill 0.5s ease;
}


.line-viewer-red-text {
  color: var(--accent-color);
}

.line-viewer-red-icon {
  fill: var(--accent-color);
}