.search {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.search-content {
  display: flex;
  flex: 1;
  overflow: hidden;

  margin-top: 20px;
}

.search-filter {
  width: auto;
  overflow-y: auto;

}
.search-results {
  width: 40vw;
  overflow-y: auto;
  margin-left: 5vw;
}

.searched-line-spacer-temp {
  height: 12px;
  width: 100%;
}

.pinned-new-line-wrapper {
  height: 40px;
  width: 40px;
  background-color: var(--accent-color);
  color: var(--background-color);
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 8px;
  text-align: center;
  align-content: center;

  font-size: 25px;
  font-family: "GeneralSans-Regular";
  transition: all 0.5s ease;
}

.pinned-new-line-wrapper:hover {
  background-color: var(--text-color);
  cursor: pointer;
}