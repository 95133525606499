.animated-waffle-effect {
    opacity: 0;
    animation: fadeInFromTop 1s ease forwards;
    transition: background-color 0.3s ease;
  }
  
  @keyframes fadeInFromTop {
    0% {
      opacity: 0;
      transform: scale(1);
      background-color: white;

      
    }
    50%{

        transform:scale(1.08);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }