.mobile-profile-stat-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;

  // Styles for Label component within our component
  .label-container {
    width: 100%;

    .label-content {
      width: 100%;
    }
  }
}

.mobile-stat-row,
.mobile-streak-badge-row {
  display: flex;
  width: 100%;
}

.mobile-streak-badge-row {
  justify-content: space-between;
}

.mobile-stat-view {

  justify-content: space-between;

  height: 60px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--light-bounding-color);
  display: flex;
  justify-content: center;
  align-items: center;

}
.mobile-badge-view {
  height: 60px;
  width: 100%;
  border-radius: 12px;
  background-color: var(--light-bounding-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-stat-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 10%;
}

.mobile-stat-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  padding: 0 5px;
}

.mobile-profile-stat-main {
  color: var(--accent-color);
  font-size: 24px;
  font-family: generalSans-SemiBold, sans-serif;
  margin: 0;
  line-height: 1.2;
}

.mobile-profile-stat-sub-header {
  color: var(--text-color);
  font-size: 12px;
  font-family: generalSans-SemiBold, sans-serif;
  margin: 2px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.mobile-streak-view {
  height: 60px;
  width: 15%;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-badges-label-bounding {
  width: 100%;
}

.mobile-streak-value-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mobile-streak-number {
  font-size: 40px;
  font-family: generalSans-SemiBold, sans-serif;
  margin: 0;
  line-height: 1;
}

.mobile-streak-sub-heading {
  font-size: 12px;
  font-family: generalSans-SemiBold, sans-serif;
  margin: 2px 0 0;
}