.title-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
    width:100%;
    gap:20px;
    padding: 0 10px;
    box-sizing: border-box;
    .info{
        width:100%;
        display: flex;
        gap:30px;
        flex-direction: column;
    }
    .header{
        margin-left:10px;
        margin-bottom:5px;
        font-size:13px;
        font-weight:500
    }
    .title{
        width:100%;
        .input-wrapper{
            background-color: var(--light-bounding-color);
            border-radius: 10px;
            position: relative;
        }
        input{
            position: relative;
            width:100%;
            outline: none;
            border: none;
            border-radius: 10px;
            padding: 10px;
            box-sizing: border-box;
            font-family: 'GeneralSans-Medium';
            font-size:16px;
            transition: all 0.5s;
            background-color: transparent;
            z-index: 2;
        }
        .input-wrapper::before{
            content: "";
            width:0;
            height:100%;
            border-radius: 10px;
            background-color: var(--text-color);
            transition: all 0.5s;
            display: block;
            position: absolute;
            top:0;
            left:0;
            z-index: 1;
        }

        .input-wrapper.done::before{
            width:100%;
        }
        .input-wrapper.done input{
            color: var(--background-color);
        }
    }


    
    .description{
        width:100%; 
    }
}

@media (max-width:500px) {
    .title-desc{
        .info{
            gap:10px;
        }
    }
    
    .description{
        .quill{
            /* height:100px; */
        }
    }
    
}