.multi-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.multi-selector-button {
    padding: 5px 10px;
    border-radius: 8px;
    border: none;
    background-color: var(--light-bounding-color);
    color: var(--text-color);
    font-size: 14px;
    font-family: "GeneralSans-Medium";
    cursor: pointer;
    /* transition: background-color 0.5s ease, color 0.5s ease, flex 0.5s ease, margin 0.5s ease; */
    transition: all 0.5s ease;
    position: relative;
}

.multi-selector-button:hover {
    background-color: #e6f2ff;
}

.multi-selector-button.selected {
    background-color: var(--text-color);
    color: var(--light-bounding-color);
}

.multi-selector-button.selected.merge-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -5px;
    padding-left: 15px;
}

.multi-selector-button.selected.merge-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -5px;
    padding-right: 15px;
}

.multi-selector-button.selected.merge-left.merge-right {
    z-index: 1;
}

/* .multi-selector-button.selected.merge-left::before,
.multi-selector-button.selected.merge-right::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: var(--text-color);
}

.multi-selector-button.selected.merge-left::before {
    left: -5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.multi-selector-button.selected.merge-right::after {
    right: -5px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
} */