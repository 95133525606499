.percent-completion-header {
    .create-header {
        display: flex;
        align-items: center;
        gap: 10px;

        h1 {
            position: relative;
            display: inline-block;
            font-family: "GeneralSans-SemiBold";
        }
    }

    .progress-line-container {
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: transparent;
        display: flex;
        align-items: center;

        .progress-line {
            height: 100%;
            background-color: var(--accent-color);
            transition: width 0.5s ease-in-out;
            min-width: 5px; 
            border-radius: 1.5px;
        }

        .percent-display {
            margin-left: 3px;
            font-family: "GeneralSans-Medium";
            font-size: 10px;
            color: var(--text-color);
            white-space: nowrap;
        }
    }
}