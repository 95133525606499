.url-description-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 30vw;
    height: auto;
  }
  
  .url-description-box {
    background-color: var(--light-bounding-color);
    border-radius: 12px;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
;
  }
  
  .url-title {
    color: var(--text-color);
    font-family: GeneralSans-SemiBold;
    font-size: 12px;
    text-decoration: none;
    margin-bottom: 2px;
    padding-top: 10px;
    padding-left: 15px;
  }
  
  .url-description {
    color: var(--text-color);
    font-family: GeneralSans-Regular;
    font-size: 12px;
    color: var(--text-color);
    
    padding: 0px 15px 10px 15px;
  }

  .load-more-button {
    background-color: var(--light-bounding-color);
    border: 0px;
    border-radius: 8px;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
    color: var(--text-color);
    font-family: GeneralSans-SemiBold;
    font-size: 14px;
    text-decoration: none;
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
    text-align: center;
    transition: background-color 0.5s ease;
  }

  .load-more-button:hover {
    background-color: var(--accent-color);
  }

  @media (max-width:500px) {
    .url-description-container {
        width: 100%;
      }
      
  }